export enum Routes {
  Mailing = '/mailing',
  MailingTable = '/mailing/table',
  Home = '/',
  Parsers = '/parsers',
  ParsersActivities = '/parsers/activities',
  ParsersParticipants = '/parsers/participants',
  ParsersGeolocation = '/parsers/geolocation',
  ParsersTelephones = '/parsers/telephones',
  Login = '/login',
  UnavailableServicePage = '/unavailable',
  Fail = '/fail',
  Registration = '/registration',
  Confirm = '/confirm',
  ResetPassword = '/reset-password',
  ResetPasswordSuccess = '/reset-password-success',
  Recovery = '/recovery',
  RecoveryAlert = '/recovery-alert',
  Dashboard = '/dashboard',
  Base = '/base',
  Finance = '/finance',
  Reports = '/reports',
  Inviting = '/inviting',
  Tariffs = '/tariffs',
  HomeTariffs = '/home/tariffs',
  Documents = '/documents',
  Profile = '/profile',
  Contract = '/contract',
  PrivacyPolicy = '/privacy',
  Admin = '/admin',
  AdminUsers = '/admin/users',
  AdminUser = '/admin/users/:userId',
  AdminTariff = '/admin/tariffs',
  AdminBots = '/admin/bots',
  AdminBotsAll = '/admin/bots/all',
  // AdminBotsStat = '/admin/bots/stat',
  AdminBot = '/admin/bots/:botId',
  AdminFinance = '/admin/finance',
  AdminProfile = '/admin/profile',
  AdminDocuments = '/admin/documents',
  AdminPartner = 'https://partner-system.ru/',
  AdminInviting = '/admin/inviting',
}
