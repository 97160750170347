import styles from './tasks.module.sass';
import Table from '../../ui/table/Table';
import TableHead from '../../ui/table/tableHead/TableHead';
import TableRow from '../../ui/table/tableRow/TableRow';
import TableBody from '../../ui/table/tableBody/TableBody';
import TableCell from '../../ui/table/tableCell/TableCell';
import TableContainer from '../../ui/table/tableContainer/TableContainer';
import { ParserTask } from '../../../types/parserTask';
import { FC, useState, useEffect } from 'react';
import { BASE_URL, Roles } from '../../../consts/consts';
import { useAppSelector } from '../../../hooks/redux';
import { api } from '../../../services/api';
import {
  getTaskOperationName,
  getTaskStatusName,
} from '../../../utils/getTaskFieldName';
import IconButton from '../../ui/iconButton/IconButton';
import Tooltip from '../../ui/tooltip/tooltip';
import { ReactComponent as DownloadIcon } from '../../../assets/images/icons/download.svg';

// interface TasksProps {
//   tasks: ParserTask[];
// }

const Tasks: FC = () => {
  const [tasks, setTasks] = useState<ParserTask[]>([]);
  const [limit, setLimit] = useState(20);

  const role = useAppSelector((state) => state.UserData.user?.role.name);

  useEffect(() => {
    const offset = 0;
    if (role === Roles.Admin || role === Roles.SuperUser) {
      api
        .get(`/telegram/tasks/?offset=${offset}&limit=${limit}`)
        .then((response) => {
          setTasks(response.data);
        })
        .catch((error) => console.error(error));
    } else {
      api
        .get(`/telegram/tasks/me?offset=${offset}&limit=${limit}`)
        .then((response) => {
          setTasks(response.data);
        })
        .catch((error) => console.error(error));
    }
  }, [limit]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const container = e.currentTarget;
    const containerHeight = container.clientHeight;
    const contentHeight = container.scrollHeight;
    const scrollTop = container.scrollTop;

    if (scrollTop + containerHeight >= contentHeight - 10) {
      setLimit((prevLimit) => prevLimit + 5);
    }
  };

  return (
    <section className={styles.sectionTableTasks}>
      <TableContainer style={{ maxHeight: '100%' }} onScroll={handleScroll}>
        {tasks.length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell variant="head">Дата создания</TableCell>
                <TableCell variant="head">Название задачи</TableCell>
                {(role === Roles.Admin || role === Roles.SuperUser) && (
                  <TableCell variant="head">Автор</TableCell>
                )}
                <TableCell variant="head">Результат задачи</TableCell>
                <TableCell variant="head">Операция</TableCell>
                <TableCell variant="head">Статус</TableCell>
                {(role === Roles.Admin || role === Roles.SuperUser) && (
                  <TableCell variant="head">Load</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks.map((task) => (
                <TableRow key={task.id}>
                  <TableCell>
                    {new Date(task.created_at).toLocaleDateString('ru-Ru', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </TableCell>
                  <TableCell>{task.title}</TableCell>
                  {(role === Roles.Admin || role === Roles.SuperUser) && (
                    <TableCell>{task.user.email}</TableCell>
                  )}
                  <TableCell>{task.data_count}</TableCell>
                  <TableCell>{getTaskOperationName(task.operation)}</TableCell>
                  <TableCell
                    className={
                      task.work_status === 'in_processing'
                        ? styles.statusCell_in_processing
                        : task.work_status === 'in_waiting'
                        ? styles.statusCell_in_waiting
                        : task.work_status === 'failed'
                        ? styles.statusCell_failed
                        : task.work_status === 'success'
                        ? styles.statusCell_success
                        : ''
                    }
                  >
                    {getTaskStatusName(task.work_status)}
                  </TableCell>
                  {(role === Roles.Admin || role === Roles.SuperUser) &&
                    task.data_count > 0 && (
                      <TableCell>
                        <IconButton
                          onClick={() =>
                            window.open(
                              `${BASE_URL}/telegram/tasks/me/${task.id}/download`,
                              '_blank',
                            )
                          }
                          disabled={
                            task.work_status === 'failed' ||
                            task.operation === 'mailing'
                          }
                        >
                          <>
                            <Tooltip
                              text="Загрузить результат"
                              direction="left"
                            >
                              <DownloadIcon
                                style={{ width: '16px', height: '16px' }}
                              />
                            </Tooltip>
                            <span className="visually-hidden">
                              Загрузить результат
                            </span>
                          </>
                        </IconButton>
                      </TableCell>
                    )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <p className={styles.textNoData}>
            Здесь будет история созданых Вами задач с датами создания, статусами
            и названиями
          </p>
        )}
      </TableContainer>
    </section>
  );
};
export default Tasks;
